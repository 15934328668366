import { createSlice } from '@reduxjs/toolkit'

import config from 'config'

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu(state, action) {
      const { id } = action.payload;
      state.isOpen = [id];
    },
    setMenu(state, action) {
      const { opened } = action.payload;
      state.opened = opened;
    }
  }
})

export const { openMenu, setMenu } = menuSlice.actions

export default menuSlice.reducer