// assets
import { IconMessage2Code } from '@tabler/icons';



// constant
const icons = { IconMessage2Code };

// ==============================|| Navigation MENU ITEMS ||============================== //

const previous_7days = {
    id: 'previous_7days',
    title: 'Previous 7 Days',
    type: 'group',
    children: Array.from(Array(2).keys()).map((i) => ({
      id: `chat-00${i+2}`,
      title: `Chat ${i+2}`,
      type: 'item',
      url: '/chat',
      icon: icons.IconMessage2Code,
      breadcrumbs: false
  }))
};

export default previous_7days;
