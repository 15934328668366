import {api} from './api';
import config from '../config';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        body: data
      })
    }),
    registerWithInviteCode: builder.mutation({
      query: ({invite_code, ...data}) => ({
        url: `/invite/signup?invite_code=${invite_code}`,
        method: 'POST',
        body: data
      })
    }),
    login: builder.mutation({
      query: ({username, password}) => ({
        url: '/auth/jwt/login',
        method: 'POST',
        // backend user login service accept Form data
        body: new URLSearchParams({username, password}),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    }),
    renew: builder.mutation({
      query: () => ({
        url: '/users_auth/jwt/renew',
        method: 'POST'
      })
    }),
    verify: builder.mutation({
      query: ({token}) => ({
        url: '/auth/verify',
        method: 'POST',
        body: {token}
      })
    }),
    forgetPassword: builder.mutation({
      query: ({email}) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: {email}
      })
    }),
    resetPassword: builder.mutation({
      query: ({password, token}) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {password, token}
      })
    }),
    createGuestUser: builder.mutation({
      query: () => ({
        url: '/user_manage/create_guest_user',
        method: 'POST',
        body: {app_name: config.appName, app_version: config.appVersion}
      })
    }),
  })
});

export const {
  useRegisterMutation,
  useRegisterWithInviteCodeMutation,
  useLoginMutation,
  useRenewMutation,
  useVerifyMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useCreateGuestUserMutation,
} = authApi;
