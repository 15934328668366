import { lazy } from 'react';
import config  from 'config';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ForgetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgetPassword'))); 
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword'))); 

const AccountCreated = Loadable(lazy(() => import('views/pages/authentication/AccountCreated')));
const ActivateAccount = Loadable(lazy(() => import('views/pages/authentication/ActivateAccount')));
const LoginWithInviteCode = Loadable(lazy(() => import('views/pages/authentication/LoginWithInviteCode')));
const SignupWithOneTimeInviteCode = Loadable(lazy(() => import('views/pages/authentication/SignupWithOneTimeInviteCode')));

const Logout = Loadable(lazy(() => import('views/pages/authentication/Logout')));

const Documents = Loadable(lazy(() => import('views/other/Documents')));
const TermsOfUse = Loadable(lazy(() => import('views/pages/TermsOfUse')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/PrivacyPolicy')));

const Demo = Loadable(lazy(() => import('views/pages/demo')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/forget_password',
            element: <ForgetPassword />
        },
        {
            path: '/reset_password',
            element: <ResetPassword />
        },
        {
            path: '/account_created',
            element: <AccountCreated />
        },
        {
            path: '/activate_account',
            element: <ActivateAccount />
        },
        {
            path: '/invitation',
            element: <LoginWithInviteCode />
        },
        {
            path: '/invite_signup',
            element: <SignupWithOneTimeInviteCode />
        },
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path: '/demo',
            element: <Demo />
        },
        {
            path: '/docs',
            element: <Documents />
        },
        {
            path: '/terms-of-use',
            element: <TermsOfUse />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
    ]
};

export default AuthenticationRoutes;
