import { lazy } from 'react';
import config  from 'config';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const UnderMaintenance = Loadable(lazy(() => import('views/pages/maintenance/UnderMaintenance'))); 


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const MaintenanceRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/maintenance',
            element: <UnderMaintenance />
        }
    ]
};

export default MaintenanceRoutes;
