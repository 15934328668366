import {createRoot} from 'react-dom/client';

// third party
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

// project imports
import App from 'App';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorker from 'serviceWorker';
import {persistor, store} from 'store';

// style + assets
import 'assets/scss/style.scss';
import 'index.css';
import config from './config';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <PersistGate loading={null} persistor={persistor}>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
          <App/>
        </DevSupport>
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
