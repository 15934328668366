import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentProject: null,
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentProject(state, action) {
      state.currentProject = action.payload;
    },
  }
})

export const { setCurrentProject } = projectSlice.actions
export default projectSlice.reducer;