import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

// material-ui
import {ButtonBase, Grid, Typography} from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import {setMenu} from 'slices/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.menu.opened);
  const dispatch = useDispatch();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <ButtonBase
        disableRipple
        onClick={() => dispatch(setMenu({opened: true}))}
        component={Link}
        to={config.defaultPath}
        sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
        <Logo/>
        <Typography variant='caption' sx={{
          padding: '1px 3px',
          bgcolor: 'error.main',
          color: 'white',
          fontSize: '9px',
          borderRadius: '4px',
          marginLeft: '5px',
          marginBottom: '3px',
        }}>
          ADMIN
        </Typography>
      </ButtonBase>
    </Grid>
  )
}


export default LogoSection;
