import navigation from './navigation';
import other from './other';
import current from './current';
import previous_7days from './previous_7days';
import previous_30days from './previous_30days';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [current, previous_7days, previous_30days, other]
};

export default menuItems;
