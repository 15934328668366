// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import HistoryIcon from '@mui/icons-material/History';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: []
};

export default other;
