// assets
import { IconMessage2Code } from '@tabler/icons';



// constant
const icons = { IconMessage2Code };

// ==============================|| Navigation MENU ITEMS ||============================== //

const current = {
    id: 'today',
    title: 'today',
    type: 'group',
    children: Array.from(Array(1).keys()).map((i) => ({
      id: `chat-00${i+1}`,
      title: `Chat ${i+1}`,
      type: 'item',
      url: '/chat',
      icon: icons.IconMessage2Code,
      breadcrumbs: false
  }))
};

export default current;
