import { createSlice } from '@reduxjs/toolkit';
import { authApi } from 'services/auth';
import { userApi } from 'services/user';
import jwt_decode from 'jwt-decode';

export const initialState = {
  user: null,
  expiration: null,
  token: null,
  isLoggedIn: false,
  username: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      console.log('logging out');
      state.user = null;
      state.expiration = null;
      state.token = null;
      state.isLoggedIn = false;
      state.username = null;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const { sub, exp } = jwt_decode(action.payload.access_token);
        state.user = sub;
        state.expiration = exp;
        state.token = action.payload.access_token;
        state.isLoggedIn = true;
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        state.isLoggedIn = false;
      })

      .addMatcher(authApi.endpoints.createGuestUser.matchPending, (state, action) => {
      })
      .addMatcher(authApi.endpoints.createGuestUser.matchFulfilled, (state, action) => {
        const { sub, exp } = jwt_decode(action.payload.access_token);
        state.user = sub;
        state.expiration = exp;
        state.token = action.payload.access_token;
        state.isLoggedIn = true;
      })
      .addMatcher(authApi.endpoints.createGuestUser.matchRejected, (state, action) => {
        state.isLoggedIn = false;
      })

      .addMatcher(authApi.endpoints.renew.matchPending, (state, action) => {
      })
      .addMatcher(authApi.endpoints.renew.matchFulfilled, (state, action) => {
        const { sub, exp } = jwt_decode(action.payload.access_token);
        state.user = sub;
        state.expiration = exp;
        state.token = action.payload.access_token;
        state.isLoggedIn = true;
      })
      .addMatcher(authApi.endpoints.renew.matchRejected, (state, action) => {
        state.isLoggedIn = false;
      })

      .addMatcher(userApi.endpoints.loginWithInviteCode.matchPending, (state, action) => {
      })
      .addMatcher(userApi.endpoints.loginWithInviteCode.matchFulfilled, (state, action) => {
        const { sub, exp } = jwt_decode(action.payload.access_token);
        state.user = sub;
        state.expiration = exp;
        state.token = action.payload.access_token;
        state.isLoggedIn = true;
      })
      .addMatcher(userApi.endpoints.loginWithInviteCode.matchRejected, (state, action) => {
        state.isLoggedIn = false;
      })


      .addMatcher(userApi.endpoints.getMe.matchPending, (state, action) => {
      })
      .addMatcher(userApi.endpoints.getMe.matchFulfilled, (state, action) => {
        state.username = action.payload.email;
      })
      .addMatcher(userApi.endpoints.getMe.matchRejected, (state, action) => {
      });;
  }
});

export default authSlice.reducer;
export const { logout, setIsLoggedIn } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
