import { api } from './api';

export const projectsApi = api.injectEndpoints({
  tagTypes: ['Project', 'MessageUserReaction'],
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: ({ dataset_id }) => ({
        url: '/projects',
        method: 'POST',
        body: { dataset_id }
      }),
      invalidatesTags: ['Project']
    }),
    getProjects: builder.query({
      query: ({ short = false, last_n = null }) => `/projects?short=${short}${last_n ? '&last_n=' + last_n : ''}`,
      providesTags: (result, error, arg) => result ? [...result.map((project) => ({ type: 'Project', id: project._id })), 'Project'] : ['Project']
    }),
    getProject: builder.query({
      query: ({ id }) => `/projects/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Project', id: arg._id }]
    }),
    deleteProject: builder.mutation({
      query: ({ project_id }) => ({
        url: `/projects/${project_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Project']
    }),
    deleteProjects: builder.mutation({
      query: ({ project_ids }) => ({
        url: `/projects/`,
        method: 'DELETE',
        body: project_ids
      }),
      invalidatesTags: ['Project']
    }),
    updateProjectTags: builder.mutation({
      query: ({ project_id, tags }) => ({
        url: `/projects/${project_id}/tags`,
        method: 'PUT',
        body: tags
      }),
      invalidatesTags: ['Project']
    }),
    updateProject: builder.mutation({
      query: ({ project_id, data }) => ({
        url: `/projects/${project_id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Project']
    }),
    setProjectTopic: builder.mutation({
      query: ({ project_id, topic }) => ({
        url: `/projects/${project_id}/topic?topic=${topic}`,
        method: 'POST',
      }),
    }),
    getMessageReaction: builder.query({
      query: ({ message_id }) => `/messages/${message_id}/reaction`,
      providesTags: (result, error, arg) => [{ type: 'MessageUserReaction', id: arg.message_id }]
    }),
    setMessageReaction: builder.mutation({
      query: ({ message_id, reaction }) => ({
        url: `/messages/${message_id}/reaction?reaction=${reaction}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'MessageUserReaction', id: arg.message_id }]
    }),
    getRandomExample: builder.query({
      query: ({ count }) => ({
        url: `/projects/get_examples?count=${count}&timestamp=${new Date().getTime()}`
      }),
    }),
    getPdfPages: builder.query({
      query: ({ filepath }) => ({
        url: `/fileserver/pdf-pages/${filepath}`,
        method: 'GET',
      }),
    }),
  })
});

export const {
  useCreateProjectMutation,
  useGetProjectQuery,
  useGetProjectsQuery,
  useDeleteProjectMutation,
  useDeleteProjectsMutation,
  useUpdateProjectTagsMutation,
  useUpdateProjectMutation,
  useSetProjectTopicMutation,
  useRatingAIMessageMutation,
  useGetRandomExampleQuery,
  useGetPdfPagesQuery,
  useGetMessageReactionQuery,
  useSetMessageReactionMutation,
} = projectsApi;
