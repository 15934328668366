import { lazy } from 'react';
import config from 'config';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const ErrorPage = Loadable(lazy(() => import('views/errors/ErrorPage')));
const Error404Page = Loadable(lazy(() => import('views/errors/404')));
const ErrorNoDataPage = Loadable(lazy(() => import('views/errors/NoData')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ErrorPageRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/error',
      element: <ErrorPage />
    },
    {
      path: '/404',
      element: <Error404Page />
    },
    { path: '/no_data', element: <ErrorNoDataPage /> },
  ]
};

export default ErrorPageRoutes;
