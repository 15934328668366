import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import menuReducer from 'slices/menu';
import authReducer from 'slices/auth';
import userReducer from 'slices/user';
import projectReducer from 'slices/project';

import { api } from 'services/api';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'expiration', 'token']
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    menu: menuReducer,
    user: userReducer,
    project: projectReducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
