import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    profile: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
});

export default userSlice.reducer;
