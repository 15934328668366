// assets
import { IconMessage2Code } from '@tabler/icons';

// constant
const icons = { IconMessage2Code };

// ==============================|| Navigation MENU ITEMS ||============================== //

const previous_30days = {
    id: 'previous_30days',
    title: 'Previous 30 Days',
    type: 'group',
    children: Array.from(Array(5).keys()).map((i) => ({
        id: `chat-00${i+3}`,
        title: `Chat ${i+3}`,
        type: 'item',
        url: '/chat',
        icon: icons.IconMessage2Code,
        breadcrumbs: false
    }))
};

export default previous_30days;
